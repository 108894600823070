<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
 export default {
   name: 'Root',
 }
</script>

<style lang="scss">
 @import url('https://fonts.googleapis.com/css?family=Roboto');
 @import "~bulma/sass/utilities/_all";
 
 $primary: #e62249;
 $primary-invert: findColorInvert($primary);

 $background: #2f3d46;
 $foreground: $white;

 $input-placeholder-color: $grey-dark;

 $table-color: $black;
 $table-background-color: $white; //#2f3d46;
 $table-head-cell-color: $white;
 $table-cell-border: 1px solid #7E7E7E;
 //$table-striped-row-even-background-color: #5d686f;
 $table-row-hover-background-color: #EBEBEB;
 $table-striped-row-even-hover-background-color: #EBEBEB;
  $dropdown-item-hover-background-color: #EBEBEB;

 $title-color: $white;
 $label-color: $white;

 $colors: (
   "white": ($white, $black),
   "black": ($black, $white),
   "light": ($light, $light-invert),
   "dark": ($dark, $dark-invert),
   "primary": ($primary, $primary-invert),
   "info": ($info, $info-invert),
   "success": ($success, $success-invert),
   "warning": ($warning, $warning-invert),
   "danger": ($danger, $danger-invert)
 );

 @import "~bulma";
 @import "~buefy/src/scss/buefy";
 
 html, body {
   width:100%;
   height: 100%;
   overflow-y: hidden;
 }
 
 #app {
   font-family: 'Roboto', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   color: $foreground;;
   width: 100%;
   height:100%;
   background: $background;
 }
 
 #nav {
   padding: 30px;
   a {
     font-weight: bold;
     color: #2c3e50;
     &.router-link-exact-active {
       color: #42b983;
     }
   }
 }

 .table {
   th {
     color: $table-color;
   }
 }

 .vue-notification {
   border: none !important;
   font-size: 1rem !important;
 }

</style>
