<template>
  <div>
    <v-tour name="stationTour" :steps="stationSteps" :options="stationOptions">
      <template slot-scope="tour">
        <transition name="fade">
          <template v-for="(step, index) of tour.steps">
            <v-step
              v-if="tour.currentStep === index"
              :key="index"
              :step="step"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
              >
              <template>
                  <div slot="actions" class="v-step__buttons">
                    <button @click.prevent="skipGuide" class="v-step__button">Skip guide</button>
                    <button @click.prevent="tour.previousStep" v-if="!tour.isFirst" class="v-step__button">Previous</button>
                    <button @click.prevent="tour.nextStep" v-if="!tour.isLast" class="v-step__button">Next</button>
                    <button @click.prevent="tour.stop" v-if="tour.isLast" class="v-step__button">Ok</button>
                  </div>
              </template>
            </v-step>
          </template>
        </transition>
      </template>
    </v-tour>
    <v-tour name="eventTour" :steps="eventSteps" :options="eventOptions">
       <template slot-scope="tour">
        <transition name="fade">
          <template v-for="(step, index) of tour.steps">
            <v-step
              v-if="tour.currentStep === index"
              :key="index"
              :step="step"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
              >
              <template>
                  <div slot="actions" class="v-step__buttons">
                    <button @click.prevent="skipGuide" class="v-step__button">Skip guide</button>
                    <button @click.prevent="tour.previousStep" v-if="!tour.isFirst" class="v-step__button">Previous</button>
                    <button @click.prevent="tour.nextStep" v-if="!tour.isLast" class="v-step__button">Next</button>
                    <button @click.prevent="tour.stop" v-if="tour.isLast" class="v-step__button">Ok</button>
                  </div>
              </template>
            </v-step>
          </template>
        </transition>
      </template>
    </v-tour>
    <v-tour name="notifyTour" :steps="notSteps" :options="eventOptions">
       <template slot-scope="tour">
        <transition name="fade">
          <template v-for="(step, index) of tour.steps">
            <v-step
              v-if="tour.currentStep === index"
              :key="index"
              :step="step"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
              >
              <template>
                  <div slot="actions" class="v-step__buttons">
                    <button @click.prevent="skipGuide" class="v-step__button">Skip guide</button>
                    <button @click.prevent="tour.previousStep" v-if="!tour.isFirst" class="v-step__button">Previous</button>
                    <button @click.prevent="tour.nextStep" v-if="!tour.isLast" class="v-step__button">Next</button>
                    <button @click.prevent="tour.stop" v-if="tour.isLast" class="v-step__button">Ok</button>
                  </div>
              </template>
            </v-step>
          </template>
        </transition>
      </template>
    </v-tour>
    <v-tour name="graphTour" :steps="graphSteps" :options="eventOptions" >
       <template slot-scope="tour">
        <transition name="fade">
          <template v-for="(step, index) of tour.steps">
            <v-step
              v-if="tour.currentStep === index"
              :key="index"
              :step="step"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels">
              <div slot="actions" class="v-step__buttons">
                <button @click.prevent="skipGuide" class="v-step__button">Skip guide</button>
                <button @click.prevent="tour.previousStep" v-if="!tour.isFirst" class="v-step__button">Previous</button>
                <button @click.prevent="tour.nextStep" v-if="!tour.isLast" class="v-step__button">Next</button>
                <button @click.prevent="tour.stop" v-if="tour.isLast" class="v-step__button">Ok</button>
              </div>
            </v-step>
          </template>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'TourGuide',
  props: ['show'],
  data () {
    return {
      skip: false,
      stationOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip guide',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish'
        }
      },
      eventOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip guide',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Ok'
        }
      },
      stationSteps: [
        {
          target: '[data-v-step="1"]',
          content: 'Select a station here by typing in its name, or click on the map to find a station in that area.',
          params: {
            placement: 'right'
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Clicking on an area in the map will zoom into that area and reveal nearby stations.',
          params: {
            placement: 'bottom'
          }
        }
      ],
      eventSteps: [
        {
          target: '.event-step',
          content: 'Select an event here.',
          params: {
            placement: 'right'
          }
        },
      ],
      notifyGuideShown: false,
      notSteps: [
        {
          target: '.notifications',
          content: 'Follow the instructions here.',
          params: {
            placement: 'bottom-start'
          }
        },
      ],
      graphGuideShown: false,
      graphSteps: [
        {
          target: '.graph-buttons',
          content: 'Click the P and S buttons to enable selection of the start of P and S waves on the graph.',
          params: {
            placement: 'right'
          }
        },
      ],
    }
  },
  methods: {
    startStationTour () {
      if (this.show && !this.skip) {
        this.$tours['stationTour'].start()
      }
    },
    endStationTour () {
      this.$tours['stationTour'].stop()
    },
    startEventTour () {
      if (this.show && !this.skip) {
        this.$tours['eventTour'].start()
      }
    },
    endEventTour () {
      this.$tours['eventTour'].stop()
    },
    startNotifyTour () {
      if (this.show && !this.skip && !this.notifyGuideShown) {
        this.$tours['notifyTour'].start()
        this.notifyGuideShown = true
      }
    },
    endNotifyTour () {
      this.$tours['notifyTour'].stop()
    },
    startGraphTour () {
      if (this.show && !this.skip && !this.graphGuideShown) {
        this.$tours['graphTour'].start()
        this.graphGuideShown = true
      }
    },
    endGraphTour () {
      this.$tours['graphTour'].stop()
    },
    skipGuide () {
      console.log('skip guide')
      this.endStationTour()
      this.endEventTour()
      this.endNotifyTour()
      this.endGraphTour()
      this.skip = true
    }
  },
  created () {
    //console.log(L)
  }
  
}
</script>

<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
