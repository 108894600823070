<template>
  <div>Logged in. Redirecting soon.</div>
</template>

<script>
 import ApplicationUserManager from '@/services/auth-service'

 export default {
   name: 'LoginCallback',
   created: async function () {
     try {
       await ApplicationUserManager.signinRedirectCallback()
       await ApplicationUserManager.refreshUserInfo()
       this.$router.push({ name: 'home' })
     } catch (e) {
       console.log('error logincallback: ', e)
     }
   }
 }
</script>
