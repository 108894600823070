<template>
  <div class="full-height container is-fluid is-marginless main columns">
    <div class="is-paddingless full-height se-picker">
      <div class="header">
        <a class="header-link" href="https://raspberryshake.org/" target="_blank">
          <img class="header-logo" src="../assets/locator-logo.png"  />
        </a>
      </div>
      <div class="se-title has-text-left">
        <p class="has-text-weight-bold">Latest events recorded</p>
      </div>
      <station-picker data-v-step="1" class="station-picker" v-on:update:station="onStationSelected" v-on:reset="resetAll" />
      <event-picker ref="eventPicker" class="event-picker-component event-step"  v-on:update:event="onEventSelected" :selected="event" />
    </div>

    <div class="column is-paddingless">
      <div class="map-wave is-paddingless">
        <map-view ref="map" class="map" data-v-step="2"
                :location="location" :event="event" :guess="guess"
                :stations="stations" :picked="pickedStations" :circles="circles"
                v-on:update:station="onMapStationSelected"
                v-on:update:epicenter="onEpicenterPicked"
                v-on:update:bounds="onBoundsUpdated"
                :show-custom-controls="showControls" />
      </div>
      <div class="pickers is-paddingless is-marginless">
        <waveform-picker ref="wavePicker" v-if="dataStation && event"
                        class="wave-picker is-marginless"
                        :time-data="timeData" :waveform="waveform"
                        :station="dataStation" :event="event" :info="info"
                        :psValues="psValues" v-on:update:ps="onPSValuesUpdated"
                        v-on:waveform-close="onWaveformClose"
                        :tooltipsActive="showWaveformTooltips"
                        v-on:update:interval="onIntervalSelected" >
        </waveform-picker>
      </div>
    </div>
    <notifications group="guide" position="top right" />
    <notifications group="warning" position="bottom center" />
    <!-- <v-tour name="stationTour" :steps="stationSteps" :options="stationOptions"></v-tour>
    <v-tour name="eventTour" :steps="eventSteps" :options="eventOptions"></v-tour>
    <v-tour name="notifyTour" :steps="notSteps" :options="eventOptions"></v-tour>
    <v-tour name="graphTour" :steps="graphSteps" :options="eventOptions" ></v-tour> -->
    <TourGuide ref="guide" :show="true" />
    <div class="copyright-div">
      <span class="copyright-text">Copyright 2020 - Raspberry Shake S.A.</span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MapView from '@/components/MapView.vue'
import StationPicker from '@/components/StationPicker.vue'
import EventPicker from '@/components/EventPicker.vue'
import WaveformPicker from '@/components/WaveformPicker.vue'
import TourGuide from '@/components/TourGuide.vue'
import { getDistance, getEvents } from '@/services/fdsn.js'
import { mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'home',
  components: {
    MapView,
    StationPicker,
    EventPicker,
    WaveformPicker,
    TourGuide
  },
  data () {
    return {
      loggedIn: false,
      firstEventFetch: true,
      stations: [],
      pickedStations: [],
      pickedInfos: [],
      dataStation: null,
      location: null,
      event: null,
      info: null,
      guess: [],
      markers: [],
      timeData: [],
      waveform: [],
      circles: [],
      psValues: {},
      waveformLoading: false,
      showWaveform: false,
      showControls: false,
      showWaveformTooltips: false,
      // stationOptions: {
      //   useKeyboardNavigation: false,
      //   labels: {
      //     buttonSkip: 'Skip guide',
      //     buttonPrevious: 'Previous',
      //     buttonNext: 'Next',
      //     buttonStop: 'Finish'
      //   }
      // },
      // eventOptions: {
      //   useKeyboardNavigation: false,
      //   labels: {
      //     buttonSkip: 'Skip guide',
      //     buttonPrevious: 'Previous',
      //     buttonNext: 'Next',
      //     buttonStop: 'Ok'
      //   }
      // },
      // stationSteps: [
      //   {
      //     target: '[data-v-step="1"]',
      //     content: 'Select a station here by typing in its name, or click on the map to find a station in that area.',
      //     params: {
      //       placement: 'right'
      //     }
      //   },
      //   {
      //     target: '[data-v-step="2"]',
      //     content: 'Clicking on an area in the map will zoom into that area and reveal nearby stations.',
      //     params: {
      //       placement: 'bottom'
      //     }
      //   }
      // ],
      // eventSteps: [
      //   {
      //     target: '.event-step',
      //     content: 'Select an event here.',
      //     params: {
      //       placement: 'right'
      //     }
      //   },
      // ],
      // notifyGuideShown: false,
      // notSteps: [
      //   {
      //     target: '.notifications',
      //     content: 'Follow the instructions here.',
      //     params: {
      //       placement: 'left'
      //     }
      //   },
      // ],
      // graphGuideShown: false,
      // graphSteps: [
      //   {
      //     target: '.graph-buttons',
      //     content: 'Click the P and S buttons to enable selection of the start of P and S waves on the graph.',
      //     params: {
      //       placement: 'bottom'
      //     }
      //   },
      // ],
    }
  },
  computed: {
    ...mapState({
      authenticated: state => state.login.authenticated,
      nearby: state => state.stations.nearby
    }),
    ...mapGetters([
      'getStationsForBounds'
    ]),
  },
  watch: {
    authenticated: function (val) {
      console.log('authentication changed: ', val)
      this.$notify({
        width: '35rem',
        group: 'guide',
        duration: -1,
        type: 'success',
        position: 'top left',
        text: 'Please start with selecting a station or an area on the map by clicking on it',
      })
    }
  },
  mounted () {
    console.log('mounted')
    //display all events in the world for last month
    this.loadGlobalEvents()
    this.$refs.guide.startStationTour()
    //this.$tours['stationTour'].start()
    //if (this.authenticated) {

    //this.setActiveNotification(notification)
    //}
  },
  methods: {
    ...mapActions([
      'loadNearbyStations',
      'clearEvents',
      'setActiveNotification',
      'closeNotification',
    ]),
    loadGlobalEvents () {
      var lastWeek = moment().subtract(7, 'days').format('Y-MM-DDTHH:mm:ss')
      var params = { 
        maxlat: 90,
        maxlon: 180,
        minlat: -90,
        minlon: -180,
        start: lastWeek,
        maxdepth: 500,
        minmag: 3.5
      }
      getEvents(params).then((events) => this.$refs.map.setEvents(events))
    },
    resetAll () {
      this.firstEventFetch = true
      this.stations = []
      this.pickedStations = []
      this.pickedInfos = []
      this.dataStation = null
      this.event = null
      this.info = null
      this.guess = []
      this.markers = []
      this.timeData = []
      this.waveform = []
      this.circles = []
      this.psValues = {}
      this.$refs.eventPicker.reset()
      this.$refs.map.zoomStart()
      this.loadGlobalEvents()
    },
    onStationSelected (station) {
      //this.$tours['notifyTour'].stop()
      this.$refs.guide.startNotifyTour()
      this.$notify({
        group: 'guide',
        clean: true
      })
      this.dataStation = station
      this.$refs.map.zoomToStation(station)

      if (!(this.dataStation.name in this.psValues)) {
        this.psValues[this.dataStation.name] = { }
      }

      this.info = null
      if (this.dataStation.name in this.pickedInfos) {
        this.info = this.pickedInfos[this.dataStation.name]
      }

      this.event = null
    },
    onMapStationSelected (station) {
      //this.$tours['notifyTour'].stop()
      this.$refs.guide.endNotifyTour()
      this.$notify({
        group: 'guide',
        clean: true
      })
      this.dataStation = station

      this.info = null
      if (this.dataStation.name in this.pickedInfos) {
        this.info = this.pickedInfos[this.dataStation.name]
      }

      if (!(this.dataStation.name in this.psValues)) {
        this.psValues[this.dataStation.name] = { }
      }

      if (this.event) {
        if (!(this.event.id in this.psValues[this.dataStation.name])) {
          this.psValues[this.dataStation.name][this.event.id] = { p: 0, s: 0 }
        }

        this.$nextTick(() => {
          this.$refs.wavePicker
            .loadWaveform(this.psValues[this.dataStation.name][this.event.id])
            .then( _ => {
                this.$refs.guide.startGraphTour()
            })
            .catch(err => {
              this.waveformLoading = false
              this.$refs.wavePicker.clearWaveformData()
              //this.$refs.wavePicker.clearSelection()
              this.$notify({
                group: 'warning',
                clean: true
              })
              setTimeout(() => {
                this.$notify({
                  group: 'warning',
                  duration: 3000,
                  type: 'error',
                  position: 'top center',
                  text: `No data found!`,
                })
              }, 500)
            })
        })
      }
    },
    onBoundsUpdated (bounds) {
      this.$refs.map.hideEvents()
      this.$refs.guide.endStationTour()
      //this.$tours['stationTour'].stop()
      this.$refs.eventPicker.getEvents(bounds)
      .then(_ => {
        if (this.firstEventFetch) {
          //this.$tours['eventTour'].start()
          this.$refs.guide.startEventTour()

          this.$notify({
            group: 'guide',
            clean: true
          })
          // setTimeout(() => {
          //   this.$notify({
          //     group: 'guide',
          //     duration: -1,
          //     type: 'success',
          //     position: 'top right',
          //     text: 'Select an event from the list',
          //   })
          // }, 500)
          //this.setActiveNotification(notification)
          this.firstEventFetch = false
        }
      })
      this.stations = this.getStationsForBounds(bounds)

    },
    onEventSelected (event) {
      //this.$tours['eventTour'].stop()
      this.$refs.guide.endEventTour()
      this.$notify({
        group: 'guide',
        clean: true
      })
      if (!this.dataStation) {

        setTimeout(() => {
            this.$notify({
              group: 'guide',
              duration: -1,
              type: 'success',
              position: 'top right',
              text: 'Select a station on the map',
            })
            if (!this.notifyGuideShown) {
              this.$refs.guide.startNotifyTour()
              //this.$tours['notifyTour'].start()
              this.notifyGuideShown = true
            }
          }, 500)
      }
      if (this.event != null) {
        this.dataStation = null
      }

      this.event = event

      this.circles = []
      this.showControls = false
      this.timeData = []
      this.waveform = []
      this.pickedStations = []
      this.$refs.map.disableEpicenterMode()

      //this.waveformLoading = true
      if (this.dataStation) {
        if (!(this.event.id in this.psValues[this.dataStation.name])) {
          this.psValues[this.dataStation.name][this.event.id] = { p: 0, s: 0 }
        }
        this.$nextTick(() => {
          this.$refs.wavePicker
              .loadWaveform(this.psValues[this.dataStation.name][this.event.id])
              .then( _ => {
                this.$refs.guide.startGraphTour()
              })
              .catch(err => {
                //this.waveformLoading = false
                this.$notify({
                  group: 'warning',
                  duration: 3000,
                  type: 'error',
                  position: 'top center',
                  text: `No data found!`,
                })
              })
        })
      }
    },
    onWaveformClose () {
      this.pickedStations = this.pickedStations.filter(s => s !== this.dataStation.name)
      this.psValues[this.dataStation.name][this.event.id] = { p: 0, s: 0 }
      var idx = this.circles.findIndex(c => c.station.name === this.dataStation.name)
      if (idx > -1) {
        this.circles.splice(idx, 1)
      }
      this.dataStation = null
    },
    onPSValuesUpdated (psValues) {
      this.psValues[this.dataStation.name][this.event.id] = psValues

      if (psValues.p === 0 || psValues.s === 0) {
        var idx = this.circles.findIndex(c => c.station.name === this.dataStation.name)
        if (idx > -1) {
          this.circles.splice(idx, 1)
        }

        this.pickedStations = this.pickedStations.filter(s => s !== this.dataStation.name)
        delete this.pickedInfos[this.dataStation.name]
      }
    },
    onIntervalSelected (interval) {
      getDistance(interval, [this.dataStation.latitude, this.dataStation.longitude])
            .then(distance => {
              var info = {
                radius: (distance / 1000).toFixed(2),
                travel: moment.utc(distance / 5.5).format("HH:mm:ss"),
                interval: moment.utc(interval * 1000).format("HH:mm:ss")
              }
              this.pickedInfos[this.dataStation.name] = info
              this.info = info

              var idx = this.circles.findIndex(c => c.station.name === this.dataStation.name)
              var circle = {
                id: 'circle-' + this.dataStation.name,
                station: this.dataStation,
                center: [this.dataStation.latitude, this.dataStation.longitude],
                radius: distance
              }

              if (idx > -1 ) {
                this.circles.splice(idx, 1, circle)
              } else {
                this.circles.push(circle)
              }

              this.pickedStations.push(this.dataStation.name)

              if (this.circles.length > 2) {
                this.$notify({
                  group: 'guide',
                  clean: true
                })
                setTimeout(() => {
                  this.$notify({
                    group: 'guide',
                    duration: -1,
                    type: 'success',
                    position: 'top right',
                    text: 'You can now try to guess the epicenter or add more stations.',
                  })
                }, 500)
                this.showControls = true
              } else {
                this.$notify({
                  group: 'guide',
                  clean: true
                })
                setTimeout(() => {
                  this.$notify({
                    group: 'guide',
                    duration: -1,
                    type: 'success',
                    position: 'top right',
                    text: 'Select some more stations on the map to pinpoint the epicenter.',
                  })
                  if (!this.notifyGuideShown) {
                    this.$tours['notifyTour'].start()
                    this.notifyGuideShown = true
                  }
                }, 500)
                //this.setActiveNotification(notification)
              }
            })
    },
    onEpicenterPicked (epicenter) {
      var difference = this.calcDistance([this.event.latitude, this.event.longitude], [epicenter.lat, epicenter.lng]) / 1000
      this.$notify({
        group: 'guide',
        clean: true
      })
      setTimeout(() => {
        this.$notify({
          group: 'guide',
          duration: -1,
          type: 'success',
          position: 'top right',
          text: `You are about ${difference.toFixed(2)} kilometers away from the epicenter.`,
        })
      }, 500)
      //this.setActiveNotification(notification)
    },
    calcDistance(origin, destination) {
      // return distance in meters
      var lon1 = this.toRadian(origin[1]),
          lat1 = this.toRadian(origin[0]),
          lon2 = this.toRadian(destination[1]),
          lat2 = this.toRadian(destination[0]);

      var deltaLat = lat2 - lat1;
      var deltaLon = lon2 - lon1;

      var a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
      var c = 2 * Math.asin(Math.sqrt(a));
      var EARTH_RADIUS = 6371;
      return c * EARTH_RADIUS * 1000;
    },
    toRadian(degree) {
        return degree*Math.PI/180;
    }
  }
}
</script>

<style type="scss">
.v-step {
  z-index: 9999;
}

.vue-notification {
  &.success {
    background: #50596c;
  }
}

.main {
  color: $foreground;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  background: #182832;
}

.header-link {
  width: 90%;
}

.header-logo {
  max-height: 100%;
  width: auto;
  padding: 0.25rem;
}

.header-text {
  display: flex;
  align-items: center;
}

.se-picker {
  max-height: 100%;
  width: 25rem;
}

.se-title {
  height: 2rem;
}

.station-picker {
  height: 5rem;
}

.event-picker-component {
  height: calc(100% - (3rem + 2rem + 5rem));
}

.pickers {
  height: 25%;
  width: calc(100% - 27rem);
  z-index: 999;
  padding: 1em;
  position: absolute;
  bottom: 0;
  margin: 1rem !important;
}

.full-height {
  height: 100%;
}

.map-wave {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.map {
  height: 100%;
}

.wave-picker {
  height: 100%;
}

.copyright-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 999;
}

.copyright-text {
  background: white;
  color: black;
  font-size: 8px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>
