import * as types from '@/store/mutation-types'
import { getEvents } from '@/services/fdsn.js'
import moment from 'moment'
import { clamp } from '@/util/clamp.js'

const state = {
  all: []
}

const mutations = {
  [types.ADD_EVENTS] (state, { events }) {
    events.forEach(ev => {
      if (!state.all.some(e => e.id === ev.id)) {
        state.all.push(ev)
      }
    })
    state.all.sort((eva, evb) => evb.time.valueOf() - eva.time.valueOf())
  },
  [types.CLEAR_EVENTS] (state) {
    state.all = []
  }
}

const actions = {
  clearEvents ({ commit }) {
    commit(types.CLEAR_EVENTS)
  },
  queryEvents ({ commit }, bounds) {
    commit(types.CLEAR_EVENTS)
    var lastWeek = moment().subtract(30, 'days').format('Y-MM-DDTHH:mm:ss')
    var regionalParams = { 
      maxlat: clamp(bounds.getNorth(), -90, 90),
      maxlon: clamp(bounds.getEast(), -180, 180),
      minlat: clamp(bounds.getSouth(), -90, 90),
      minlon: clamp(bounds.getWest(), -180, 180),
      start: lastWeek,
      maxdepth: 50
    }

    return getEvents(regionalParams)
      .then(events => {
        commit(types.ADD_EVENTS, { events })
      })
  },
  queryEvents2 ({ commit }, station) {
    commit(types.CLEAR_EVENTS)
    var lastMonth = moment().subtract(7, 'days').format('Y-MM-DDTHH:mm:ss')
    var globalParams = { start: lastMonth, minmag: 6.5, maxdepth: 50 }
    var regionalParams = { 
      maxlat: clamp(station.latitude + 10, -90, 90),
      maxlon: clamp(station.longitude + 10, -180, 180),
      minlat: clamp(station.latitude - 10, -90, 90),
      minlon: clamp(station.longitude - 10, -180, 180),
      start: lastMonth,
      minmag: 4.5,
      maxdepth: 50
    }
    var localParams = {
      maxlat: clamp(station.latitude + 1, -90, 90),
      maxlon: clamp(station.longitude + 1, -180, 180),
      minlat: clamp(station.latitude - 1, -90, 90),
      minlon: clamp(station.longitude - 1, -180, 180),
      start: lastMonth,
      maxdepth: 50
    }

    getEvents(globalParams)
      .then(events => {
        commit(types.ADD_EVENTS, { events })
      })

    getEvents(regionalParams)
      .then(events => {
        // events.forEach(e => e.category = 'regional')
        commit(types.ADD_EVENTS, { events })
      })

    getEvents(localParams)
      .then(events => {
        // events.forEach(e => e.category = 'local')
        commit(types.ADD_EVENTS, { events })
      })
  }
}

const getters = {
}

export default {
  state,
  actions,
  mutations,
  getters
}
