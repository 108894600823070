<template>
  <section class="station-picker">
    <b-field class="station-filter" type="is-info">
      <b-autocomplete
        ref="autocomp"
        v-model="filter"
        field="shortName"
        :loading="isFetching"
        :data="stations"
        @input="queryStations"
        placeholder="Please input a station..."
        icon="magnify"
        @select="onStationSelect"
        @keydown.native.enter="onKeyPress">
        <template slot="empty">No results found</template>
        <template slot-scope="props">
          <div class="media">
            <div class="media-content">
              {{ props.option.network + '.' + props.option.station }}
            </div>
          </div>
        </template>
      </b-autocomplete>
      <b-button icon-pack="fas" icon-left="redo" @click="resetAll">
        Reset all
      </b-button>
    </b-field>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'StationPicker',
  data () {
    return {
      filter: '',
      isFetching: false,
      selected: null,
      stations: [],
    }
  },
  computed: {
    ...mapGetters([
      'getStationsByName'
    ])
  },
  methods: {
    resetAll () {
      this.$emit('reset')
    },
    formatStation (station) {
      return station.network + '.' + station.station
    },
    onStationSelect (station) {
      if (station) {
        this.selected = station
        this.$emit('update:station', this.selected)
      }
    },
    onKeyPress (event) {
      if (this.stations.length === 1) {
        this.$refs.autocomp.setSelected(this.stations[0])
      }
    },
    queryStations () {
      if (this.filter.length > 1) {
        this.isFetching = true
        this.stations = this.getStationsByName(this.filter)
        this.isFetching = false
      }
    },
    ...mapActions([
      'loadStations'
    ])
  },
  async created () {
    this.isFetching = true
    await this.loadStations()
    this.isFetching = false
  }
  
}
</script>

<style lang="scss">
 @import "~bulma/sass/utilities/_all";

 .station-picker {
   display: flex !important;
   flex-direction: column;
   justify-items: space-between;
   padding: 1.25rem 2rem;
   background: white;
   border-bottom: 1px solid #7E7E7E;
 }
 .station-list {
   overflow-y: auto;
   flex-grow: 2;
 }
 .station-filter {
   flex-shrink: 0;
 }
 
</style>
