<script>
 import { propsBinder, findRealParent }  from 'vue2-leaflet';

 var customControl =  L.Control.extend({

   options: {
     position: 'topleft'
   },

   onAdd: function (map) {
     var epiName = 'leaflet-control-epicenter'
     var container = L.DomUtil.create('div', epiName + ' leaflet-bar')
     var options = this.options

     this._pickButton  = this._createButton('epi', 'Pick epicenter',
		                            epiName + '-pick', 'fa fa-crosshairs',
                                            container, options.onpick)

     this._showButton  = this._createButton('show', 'Show epicenter',
		                            epiName + '-show', 'fa fa-eye',
                                            container, options.onshow)

     this._container = container
     return container
   },

   _createButton: function (html, title, className, faClass, container, fn) {
     var link = L.DomUtil.create('a', className, container)
     //link.innerHTML = html
     link.href = '#'
     link.title = title

     var faIcon = L.DomUtil.create('i', faClass, link)

     link.setAttribute('role', 'button')
     link.setAttribute('aria-label', title)

     L.DomEvent.disableClickPropagation(link)
     L.DomEvent.on(link, 'click', L.DomEvent.stop)
     L.DomEvent.on(link, 'click', fn, this)

     return link
   },
 });

 const props = {
   options: {
     type: Object,
     default: () => ({}),
   }
 }
 
 export default {
   name: 'LControlEpicenter',
   props: props,
   mounted() {
     const options = this.options;
     options.onpick = this.picked
     options.onshow = this.show
     
     this.mapObject = new customControl(options);
     propsBinder(this, this.mapObject, props);
     this.mapObject.addTo(this.$parent.mapObject);
   },
   methods: {
     picked () {
       this.$emit('picked')
     },
     show () {
       this.$emit('show')
     },
     getPickElement () {
       return this.mapObject._container
     },
     getShowElement () {
       return this.mapObject._showButton
     }
   },
   beforeDestroy() {
     this.mapObject.remove();
   },
   render() {
     return null;
   }
 };
</script>
