export const INITIALIZE_STATIONS = 'INITIALIZE_STATIONS'
export const NEARBY_STATIONS = 'NEARBY_STATIONS'

export const ADD_EVENTS = 'ADD_EVENTS'
export const CLEAR_EVENTS = 'CLEAR_EVENTS'

export const UPDATE_AUTH = 'UPDATE_AUTH'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'
