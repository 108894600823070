<template>
  <div></div>
</template>
<script>
 import ApplicationUserManager from '@/services/auth-service'
 
 ApplicationUserManager.signinSilentCallback()
 .then((_) => ApplicationUserManager.refreshUserInfo())
 .catch((error) => {
   console.error(error)
 })

 export default {
   name: 'SilentLogin'
 }
</script>
