<template>
  <section class="event-picker">
    <div ref="list" class="event-list">
      <table class="table is-fullwidth is-hoverable" >
        <tbody>
          <template v-if="selected">
            <tr class="is-selected">
              <td class="event-column">
                <div class="mag-circle" :style="{ color: getMagColor(selected.mag), backgroundColor: getMagBGColor(selected.mag) }" >
                  <span class="has-text-weight-bold">{{ selected.mag }}</span>
                </div>
                <div class="event-text">
                  <p class="has-text-weight-bold">{{ selected.location }}</p>
                  <p class="has-text-weight-light has-text-grey-light">{{ selected.time.format('DD-MM-Y - HH:mm') }} - {{ parseFloat(selected.depth).toFixed(2) }}km</p>
                </div>
              </td>
            </tr>
          </template>
          <template v-if="events.length > 0 || selected">
            <tr v-for="event in nonSelectedEvents" @click="onEventSelect(event)" :id="event.id" v-bind:key="event.id">
              <td class="event-column">
                <div class="mag-circle" :style="{ color: getMagColor(event.mag), backgroundColor: getMagBGColor(event.mag) }" >
                  <span class="has-text-weight-bold">{{ event.mag }}</span>
                </div>
                <div class="event-text">
                  <p class="has-text-weight-bold">{{ event.location }}</p>
                  <p class="has-text-weight-light has-text-grey-light">{{ event.time.format('DD-MM-Y - HH:mm') }} - {{ parseFloat(event.depth).toFixed(2) }}km</p>
                </div>
              </td>
            </tr>
          </template>
          <template v-else-if="untouched" >
            <tr>
              <td>
                Please select a station or an area on the map first.
              </td>
            </tr>
          </template>
          <template v-else-if="!isLoading">
            <tr>
              <td>
                <p>No events available.</p>
                <p>Try zooming out or panning to other areas.</p>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <b-loading ref="loader" :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'EventPicker',
  props: ['selected'],
  data () {
    return {
      untouched: true,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      events: state => state.events.all
      //regionalEvents: state => state.events.all.filter(e => e.category == 'regional'),
      //localEvents: state => state.events.all.filter(e => e.category == 'local')
    }),
    nonSelectedEvents () {
      return this.events.filter((e) => this.selected ? this.selected.id !== e.id : true)
    }
  },
  methods: {
    reset() {
      this.untouched = true
      this.clearEvents()
    },
    getMagColor (mag) {
      var perc = Math.round(mag * 100 / 8)
      var r, g, b = 0;
      if(perc < 50) {
        g = 255;
        r = Math.round(5.1 * perc);
      }
      else {
        r = 255;
        g = Math.round(510 - 5.10 * perc);
      }
      var h = r * 0x10000 + g * 0x100 + b * 0x1;
      return '#' + ('000000' + h.toString(16)).slice(-6);
    },
    getMagBGColor (mag) {
      var bgColor = this.getMagColor(mag)
      bgColor = bgColor + "33"
      return bgColor
    },
    onEventSelect (event) {
      this.$emit('update:event', event)
    },
    getEvents (bounds) {
      this.isLoading = true
      return this.queryEvents(bounds)
          .then(_ => {
              this.untouched = false
              this.isLoading = false
          })
    },
    ...mapActions([
      'queryEvents',
      'clearEvents'
    ])
  },
  created () {
    //console.log(L)
  }
  
}
</script>

<style lang="scss">
.event-picker {
  background-color: white;
  display: flex !important;
  flex-direction: column;
  justify-items: space-between;
  position: fixed;
  width: inherit;
}

.event-list {
  overflow-y: auto !important;
  flex-grow: 2;
}

.mag-circle {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  //background-color: rgba(0, 0, 0, 0);
  pointer-events:none;
  text-align: center;
  padding: 0.5rem;

  span {
    color: black;
  }
}

.event-column {
  display: flex;
}

.event-text {
  margin-left: 0.5rem;
}

</style>
