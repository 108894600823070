import moment from 'moment'
import axios from 'axios'

const STATIONS_URL = 'https://data.raspberryshake.org/fdsnws/station/1/query'
const EVENTS_URL = 'https://quakelink.raspberryshake.org/events/query'
const WAVEFORM_URL = '/api/waveform'
const DISTANCE_URL = '/api/distance'

function round(number, precision) {
  var factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

export const getStations = () => {
  // parameters['format'] = 'text'
  // parameters['level'] = 'channel'
  // ?network=AM&level=channel&format=text&formatted=true&nodata=404&endafter=2023-18-01
  const params = new URLSearchParams()
  params.append('network', 'AM')
  params.append('channel', '*HZ') // only relevant channels
  params.append('level', 'channel')
  params.append('format', 'text')
  params.append('nodata', 404)
  params.append('endafter',  moment().format('YYYY-MM-DD')) // ensure only open epochs aka last updated

  return axios.get(STATIONS_URL, { params }).then(function (response) {
    var stationsRaw = response.data.split('\n')
    stationsRaw.shift()
    console.log('stationsRaw: ', stationsRaw)
    const stations = stationsRaw
      .map((line) => line.split('|'))
      .map((sta) => {
        var station = {
          network: sta[0],
          station: sta[1],
          location: sta[2],
          channel: sta[3],
          latitude: parseFloat(sta[4]),
          longitude: parseFloat(sta[5]),
          elevation: sta[6],
          //deviceName: sta.deviceName
        }
        return station
      })
    return stations
  })
}

export const getEvents = (parameters) => {
  //parameters['format'] = 'text'

  return axios.get(EVENTS_URL, {
    params: parameters
  }).then(function (response) {
    var lines = response.data.split('\n').filter((v) => v)
    var events = lines.map(function (line) {
      var fields = line.split(';')
      var event = {
        id: fields[0],
        time: moment(fields[2]),
        mag: round(fields[3], 1),
        mag_type: fields[4],
        latitude: parseFloat(fields[5]),
        longitude: parseFloat(fields[6]),
        depth: fields[7],
        location: fields[12]
      }
      return event
    })
    var eventIds = events.map(e => e.id)
    var uniqueEvents = events.filter((e, pos) => eventIds.indexOf(e.id) === pos)
    return uniqueEvents
  })
}

export const getWaveform = (parameters) => {  
  return axios.post(
    WAVEFORM_URL,
    parameters
  ).then(response => {
    if (response.data.success) {
      return response.data
    } else {
      throw 'No data found.'
    }
  })
}

export const getDistance = (interval, coords) => {
  return axios.post(
    DISTANCE_URL,
    { interval: interval, lat: coords[0], lon: coords[1] }
  ).then(response => {
    return response.data.distance
  })
}
