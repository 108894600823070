import Vue from 'vue'
import Vuex from 'vuex'

import stations from './store/modules/stations'
import events from './store/modules/events'
import login from './store/modules/login'
import notifications from './store/modules/notifications'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    stations,
    events,
    login,
    notifications
  },
  strict: process.env.NODE_ENV !== 'production'
})
