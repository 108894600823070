import { UserManager } from 'oidc-client'
import * as constants from '../constants'
import store from '@/store'

class ApplicationUserManager extends UserManager {

  constructor() {
    super({
      authority: constants.BE_URL,
      client_id: 'locator',
      redirect_uri: constants.FE_URL + 'callback/',
      silent_redirect_uri: constants.FE_URL + 'silent-login/',
      response_type: 'id_token token',
      scope: 'openid profile',
      post_logout_redirect_uri: 'https://shakenet.raspberryshake.org',
    })
  }

  async login () {
    await this.signinRedirect()
    return await this.refreshUserInfo()
  }

  async logout () {
    await this.signoutRedirect()
    return this.refreshUserInfo()
  }

  async refreshUserInfo() {
    const user = await this.getUser()

    if (user) {
      store.commit('UPDATE_AUTH', { authenticated: true, user })
    } else {
      store.commit('UPDATE_AUTH', { authenticated: false, user: null })
    }
  }

  async userExists () {
    const user = await this.getUser()
    return user != null
  }

  async silentLogin () {
    try {
      await this.signinSilent()
      await this.refreshUserInfo()
    } catch(e) {
      return false
    }
  }
}

const applicationUserManager = new ApplicationUserManager()
export { applicationUserManager as default }
