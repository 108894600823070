import * as types from '@/store/mutation-types'

const state = {
  active: null
}

const mutations = {
  [types.SET_NOTIFICATION] (state, { notification }) {
    if (state.active) {
      state.active.close()
    }
    state.active = notification
  },
  [types.CLOSE_NOTIFICATION] (state) {
    console.log(state.active)
    if (state.active) {
      state.active.close()
    }
  }
}

const actions = {
  setActiveNotification ({ commit }, notification) {
    commit(types.SET_NOTIFICATION, { notification })
  },
  closeNotification ({ commit }) {
    commit(types.CLOSE_NOTIFICATION)
  }
}

export default {
  state,
  actions,
  mutations
}
