<template>
  <div class="app-container">
    <router-view/>
  </div>
</template>

<script>
 import ApplicationUserManager from '@/services/auth-service'
 import { mapState } from 'vuex'
 
 export default {
   name: 'App',
   computed: {
     ...mapState({
       authenticated: state => state.login.authenticated,
       user: state => state.login.user
     })
   },
   async created() {
    //  if (!this.authenticated) {
    //   await ApplicationUserManager.silentLogin()
    //  }
    //  if (!this.authenticated) {
    //    await ApplicationUserManager.login()
    //  }
   }
 }
</script>

<style lang="scss">
 .app-container {
   width: 100%;
   height:100%;
 }
</style>
