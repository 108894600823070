<template>
  <section class="card columns">
    <div class="column info-col is-marginless is-paddingless">
      <div class="col-header">
        <p class="has-text-weight-bold is-size-6">Station</p>
      </div>
      <div>
        <p class="has-text-weight-bold" v-if="station">{{ station.network + '.' + station.station }}</p>
        <p class="has-text-weight-light has-text-grey" v-if="station">LAT: {{ parseFloat(station.latitude).toFixed(2) }}</p>
        <p class="has-text-weight-light has-text-grey" v-if="station">LON: {{ parseFloat(station.longitude).toFixed(2) }}</p>
        <p class="has-text-weight-light has-text-grey" v-if="station">ELEV: {{ station.elevation }}m</p>
      </div>
    </div>
    <div class="column is-10 is-paddingless is-marginless waveform-col">
      <div class="waveform-header col-header">
        <p class="has-text-weight-bold is-size-6">Filter</p>
        <b-field class="filter-field">
          <b-select v-model="filter" @input="onFilterChanged">
            <option value="0">Original</option>
            <option value="1">Very local</option>
            <option value="2">Local</option>
            <option value="3">Regional</option>
            <option value="4">Teleseismic</option>
          </b-select>
        </b-field>
        <template v-if="info">
          <p class="info-text is-size-6">Radius: {{ info.radius }}km</p>
          <p class="info-text has-text-weight-bold">|</p>
          <p class="info-text is-size-6">Travel time: {{ info.travel }}</p>
          <p class="info-text has-text-weight-bold">|</p>
          <p class="info-text is-size-6">p to s-wave: {{ info.interval }}</p>
          <!-- <p class="info-text">Formula: Interval<sub>p-s</sub> / (1/v<sub>s-wave</sub> - 1/v<sub>p-wave</sub>)</p> -->
        </template>
        <div class="flex-spacer" />
        <button class="close-button delete" aria-label="delete" @click="onClose"></button>
      </div>
      <div ref="graph" id="miniseed"  >
        <div class="graph-buttons">
          <a class="button graph-button" :class="{ 'selected': mode == 'P'}" @click="() => setMode('P')">
            <svg viewBox="0 0 512 512" class="icon" height="1em" width="1em">
              <path d="M175.536-164.135h35.977c22.232,0,36.341,16.225,36.341,37.399 c0,21.163-14.108,37.387-36.341,37.387h-35.977V-164.135z 
                      M122.618-70.291c0,16.577,9.876,27.511,27.523,27.511h62.43 
                      c51.871,0,90.317-33.872,90.317-84.309c0-51.507-39.868-83.615-88.201-83.615h-39.151v-57.856 
                      c0-17.647-11.287-27.511-26.465-27.511c-15.167,0-26.453,9.865-26.453,27.511V-70.291L122.618-70.291z" 
                      transform="matrix(1.25 0 0 -1.25 0 45)"/>
            </svg>
          </a>
          <a class="button graph-button" :class="{ 'selected': mode == 'S'}" @click="() => setMode('S')">
            <svg viewBox="0 0 512 512" class="icon" height="1em" width="1em">
              <path d="M274.432-73.818c0-13.756-9.17-26.101-23.62-26.101c-14.473,0-25.759,10.581-46.933,10.581 
                      c-15.167,0-28.922-8.112-28.922-22.938c0-36.329,117.828-13.05,117.828-104.061c0-50.449-41.631-81.852-90.317-81.852
                      c-27.159,0-85.72,6.349-85.72,39.515c0,13.756,9.17,25.042,23.632,25.042c16.577,0,36.341-13.756,59.267-13.756
                      c23.29,0,35.988,13.062,35.988,30.345c0,41.62-117.828,16.577-117.828,98.429c0,49.38,40.562,80.077,87.484,80.077
                      C225.052-38.536,274.432-45.954,274.432-73.818" 
                      transform="matrix(1.25 0 0 -1.25 0 45)"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <b-loading ref="loader" :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </section>
</template>

<script>
 import Plotly from 'plotly.js'
 import { getWaveform } from '@/services/fdsn.js'
 import moment from 'moment'

 export default {
   name: 'WaveformPicker',
   props: {
     station: {
       type: Object
     },
     event: {
       type: Object
     },
     info: {
       type: Object
     }
   },
   data: function () {
     return {
       isLoading: false,
       tooltipsShown: false,
       tooltipsActive: false,
       mode: '',
       p: 0,
       s: 0,
       py: 0,
       sy: 0,
       filter: 0,
       modebarButtons: [
         ["zoom2d", "pan2d", "zoomIn2d", "zoomOut2d", "autoScale2d", "resetScale2d"]
       ],
       shapes: [],
       annotations: [],
       data: [{ x: [], y: [], name: 'Waveform', hoverinfo: 'none', marker: { color: '#182832' } }],
       layout: {
         paper_bgcolor: '#F3F4F5',
         plot_bgcolor: '#F3F4F5',
         font: {
           family: 'Roboto, sans-serif',
           color: '#000000'
         },
         hovermode: 'x',
         autosize: true,
         shapes: this.shapes,
         xaxis: {
           //title: 'Time (s)',
           color: '#000000',
         },
         yaxis: {
           //title: 'Amplitude',
           color: '#000000',
           autorange: true,
           fixedrange: true
         },
         margin: {
           l: 0,
           r: 0,
           b: 0,
           t: 0,
           pad: 0
         },
       }
       
     }
   },
   methods: {
     onFilterChanged () {
       var start = moment(this.data[0].x[0])
       var end = moment(this.data[0].x[this.data[0].x.length - 1])
       this.getExtraWaveformData(start, end)
     },
     getExtraWaveformData(start, end) {
       var parameters = {
         start: start.format('Y-MM-DDTHH:mm:ss'),
         end: end.format('Y-MM-DDTHH:mm:ss'),
         network: this.station.network,
         station: this.station.station,
         channel: this.station.channel,
         location: this.station.location,
         filter: parseInt(this.filter)
       }
       this.isLoading = true
       return getWaveform(parameters)
         .then(data => {
           this.data[0].x = data.time
           this.data[0].y = data.data
           this.drawWaveform()
           this.isLoading = false
           return data
         })
         .catch(err => {
           this.isLoading = false
           throw err
         })
     },
     getWaveformData () {
       var tempTime = moment(this.event.time)
       var start = moment(this.event.time).subtract(30, 's').format('Y-MM-DDTHH:mm:ss')
       var end = moment(this.event.time).add(3, 'm').format('Y-MM-DDTHH:mm:ss')

       var parameters = {
         start: start, //tempTime.subtract(30, 's').format('Y-MM-DDTHH:mm:ss'),
         end: end, //tempTime.add(3, 'm').format('Y-MM-DDTHH:mm:ss'),
         network: this.station.network,
         station: this.station.station,
         channel: this.station.channel,
         location: this.station.location,
         filter: parseInt(this.filter)
       }
       this.isLoading = true
       return getWaveform(parameters)
         .then(data => {
           this.data[0].x = data.time
           this.data[0].y = data.data
           this.drawWaveform()

           this.isLoading = false
           return data
         })
         .catch(err => {
           this.isLoading = false
           throw err
         })
     },
     loadWaveform (psValues) {
       return this.getWaveformData()
                  .then(data => {
                    //this.waveformLoading = false
                    //this.showWaveform = true
                    this.updatePSValues(psValues)
                    if (!this.tooltipsShown) {
                      this.tooltipsActive = true
                      this.tooltipsShown = true
                      setTimeout(() => this.tooltipsActive = false, 5000)
                    }

                    Plotly.relayout('miniseed', {
                      'xaxis.autorange': true,
                      'yaxis.autorange': true
                    })
                  })
     },
     clearWaveformData () {
       this.data[0].x = []
       this.data[0].y = []
       this.p = 0
       this.s = 0
       this.drawShapes()
       this.drawWaveform()
     },
     updatePSValues (psValues) {
       this.p = psValues.p
       this.s = psValues.s
       this.drawShapes()
     },
     clearSelection () {
       this.p = 0
       this.s = 0
       this.drawShapes()
       this.$emit('update:ps', { p: this.p, s: this.s })
     },
     setMode (mode) {
       this.mode = mode
       if (this.mode === 'P') {
         this.p = 0
       } else {
         this.s = 0
       }
       this.$emit('update:ps', { p: this.p, s: this.s })
       this.drawShapes()
     },
     onGraphClick (data) {
       if (this.mode === 'P') {
         this.p = data.points[0].x
         this.py = data.points[0].y
         
       } else if (this.mode === 'S') {
         this.s = data.points[0].x
         this.sy = data.points[0].y
       }
       this.mode = ''
       this.$emit('update:ps', { p: this.p, s: this.s })
       this.drawShapes()
       if (this.p !== 0 && this.s !== 0) {
         this.$emit('update:interval', moment(this.s).diff(this.p, 'seconds'))
       }
     },
     onGraphChange (data) {
       console.log('graph change')
       if ('xaxis.range[0]' in data) {
         var range_s = moment(data['xaxis.range[0]'])
         var range_e = moment(data['xaxis.range[1]'])

         if (range_s.isBefore(moment(this.data[0].x[this.data[0].x.length - 1])) &&
             range_s.isAfter(moment(this.data[0].x[0])) &&
             range_e.isBefore(moment(this.data[0].x[this.data[0].x.length - 1]))) {
           return
         }

         this.getExtraWaveformData(range_s, range_e)
       }
     },
     onHover (eventdata) {
       if (this.mode !== '') {
        this.shapes[3] = {
          type: 'line',
          xref: 'x',
          yref: 'paper',
          x0: eventdata.points[0].x,
          y0: 0,
          x1: eventdata.points[0].x,
          y1: 1,
          fillcolor: '#E52148',
          opacity: 1,
          line: {
            color: '#E52148',
            width: 2
          }
        }
        this.annotations[2] = {
          xref: 'paper',
          yref: 'paper',
          x: 0.5,
          xanchor: 'right',
          y: 0,
          yanchor: 'bottom',
          text: eventdata.points[0].x,
          showarrow: false,
          font: {
            family: 'Roboto',
            color: '#000'
          },
          bordercolor: '#c7c7c7',
          borderwidth: 2,
          borderpad: 4,
          bgcolor: '#add8e6',
        }
        this.annotations[3] = {
          xref: 'paper',
          yref: 'paper',
          x: 0.5,
          xanchor: 'left',
          y: 0,
          yanchor: 'bottom',
          text: eventdata.points[0].y.toFixed(2),
          showarrow: false,
          font: {
            family: 'Roboto',
            color: '#000'
          },
          bordercolor: '#c7c7c7',
          borderwidth: 2,
          borderpad: 4,
          bgcolor: '#f1869b',
        }
       }
       Plotly.relayout('miniseed', { shapes: this.shapes, annotations: this.annotations })
     },
     onUnhover (eventdata) {
       if (this.shapes[3]) {
         delete this.shapes[3]
       }
       if (this.annotations[2]) {
         delete this.annotations[3]
         delete this.annotations[2]
       }
       Plotly.relayout('miniseed', { shapes: this.shapes, annotations: this.annotations })
     },
     drawShapes () {
       this.shapes = []
       this.annotations = []
       if (this.p !== 0) {
         this.shapes[0] = {
           type: 'line',
           xref: 'x',
           yref: 'paper',
           x0: this.p,
           y0: 0,
           x1: this.p,
           y1: 1,
           fillcolor: '#E52148',
           opacity: 1,
           line: {
             color: '#E52148',
             width: 2
           }
         }
         this.annotations[0] = {
          xref: 'x',
          yref: 'paper',
          x: this.p,
          xanchor: 'right',
          y: 1,
          yanchor: 'top',
          text: 'p-wave',
          showarrow: false
        }
       }
       if (this.s !==  0) {
         this.shapes[1] = {
           type: 'line',
           xref: 'x',
           yref: 'paper',
           x0: this.s,
           y0: 0,
           x1: this.s,
           y1: 1,
           fillcolor: '#E52148',
           opacity: 1,
           line: {
             color: '#E52148',
             width: 2
           }
         }
         this.annotations[1] = {
          xref: 'x',
          yref: 'paper',
          x: this.s,
          xanchor: 'left',
          y: 1,
          yanchor: 'top',
          text: 's-wave',
          showarrow: false
        }
       }
       if (this.p !== 0 && this.s !== 0) {
         this.shapes[2] = {
           type: 'rect',
           xref: 'x',
           yref: 'paper',
           x0: this.p,
           y0: 0,
           x1: this.s,
           y1: 1,
           fillcolor: '#E52148',
           opacity: 0.5,
           line: {
             width: 0
           }
         }
       }
       Plotly.relayout('miniseed', { shapes: this.shapes, annotations: this.annotations })
     },
     drawWaveform () {
       this.layout.dragmode = 'zoom'
       Plotly.react('miniseed', this.data, this.layout, { modeBarButtons: this.modebarButtons, displaylogo: false, responsive: true })
     },
     onClose () {
       this.$emit("waveform-close")
     }
   },
   watch: {
    station: function(newVal, oldVal) {
      this.mode = ''
    },
   },
   mounted () {
     Plotly.newPlot('miniseed', this.data, this.layout, { displaylogo: false, modeBarButtons: this.modebarButtons, responsive: true })
     this.$refs.graph.on('plotly_click', this.onGraphClick)
     this.$refs.graph.on('plotly_relayout', this.onGraphChange)
     this.$refs.graph.on('plotly_hover', this.onHover)
     this.$refs.graph.on('plotly_unhover', this.onUnhover)
   }
 }
</script>

<style lang="scss">
.waveform-col {
  display: flex;
  flex-direction: column;
}

.col-header {
  line-height: 2rem;
  margin: 0.25rem 0rem;
}

.waveform-header {
  display: flex;
}

.info-text {
  margin-left: 0.25rem;
}
.flex-spacer {
  flex-grow: 2;
}

.close-button {
  margin-right: 0.25rem;
}

.filter-field {
  margin-left: 0.5rem;
}

#miniseed {
  height: calc(100% - 1.5rem);
  padding-right: 1rem !important;
}

.graph-buttons {
  float: left;
}

.graph-button {
  fill:rgba(68, 68, 68, 0.3);
  background-color: #00000000 !important;
  border: none !important;

  :hover {
    fill:#797979;
  }

  &.selected {
    fill:#797979;
  }
}

.info-col {
  display: flex;
  flex-direction: column;
}

.ps-selects {
  align-self: center;
}

.filter-field {
  align-self: center;
}
</style>

