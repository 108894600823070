<template>
  <div id="map-container">
    <l-map ref="map" id="map" :zoom="zoom" :center="center"
          :maxBounds="[[-90, -1800], [90, 1800]]" :maxBoundsViscosity="1" :worldCopyJump="true" :zoomSnap="0"
          @click="onMapClicked($event)" @update:bounds="onBoundsUpdated">
      
      <l-control-epicenter ref="epicenterControl" v-if="showCustomControls" @picked="enableEpicenterMode" @show="toggleEpicenterShow" />
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-control position="bottomleft" >
        <div class="card icon-legend">
          <div v-if="showEventOverview" class="icon-row">
            <figure class="image is-16x16">
              <img src="../assets/event-icon.png">
            </figure>
            <p >Displaying Latest Events</p>
          </div>
        </div>
        <div v-if="!showEventOverview && hasZoomed" class="card icon-legend">
          <div class="icon-row">
            <figure class="image is-16x16">
              <img src="../assets/station-icon.png">
            </figure>
            <p >Displaying On-Line Stations</p>
          </div>
        </div>
      </l-control>
      <template v-if="showEventOverview" >
        <v-marker-cluster :options="clusterOptions" v-on:clusterclick="eventClick">
          <l-marker
            v-on:click="eventClick"
            v-for="event in events"
            :key="event.id"
            :lat-lng="[event.latitude, event.longitude]"
            :icon="eventIcon">
          </l-marker>
        </v-marker-cluster>
      </template>
      <template v-if="!epicenterMode">
        <l-marker v-for="station in stations"
                  :key="station.id"
                  :lat-lng="[station.latitude, station.longitude]" @click="onMarkerClicked(station)"
                  :icon="picked.indexOf(station.name) >= 0 ? currentStationIcon : stationIcon">
          <l-tooltip :content="station.name" />
        </l-marker>
      </template>
      <l-marker ref="epicenterMarker" v-if="showEpicenter"
                :lat-lng="[event.latitude, event.longitude]" :icon="pulseIcon"
                @add="addedEpicenter">
        <l-popup ref="popup" content="This is the epicenter!" :options="{ interactive: true, closeOnClick: false }" @remove="disableEpicenterShow" />
      </l-marker>
      <l-circle v-for="circle in circles" ref="ranges" :key="circle.id"
                :lat-lng="circle.center" :radius="circle.radius"
                :fill="false">
        <l-tooltip :content="circle.station.name"/>
      </l-circle>
      <l-marker v-if="circles.length > 2 && guess.length > 0"
                :lat-lng="guess" :icon="guessIcon" >
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import fontawesome from '@fortawesome/fontawesome'
import solid from '@fortawesome/fontawesome-free-solid'

import { LMap, LTileLayer, LMarker, LCircle, LPolyline, LLayerGroup, LTooltip, LPopup, LControl, LControlZoom, LControlAttribution, LControlScale, LControlLayers } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-pulse-icon'
import LControlEpicenter from './LControlEpicenter'
import Tooltip from 'tooltip.js'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

fontawesome.library.add(solid)

export default {
  name: 'MapView',
  props: ['location', 'stations', 'picked', 'circles', 'showCustomControls', 'event',],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControl,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
    LControlEpicenter,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  data () {
    return {
      zoom: 6,
      center: [0, 0],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', //'https://proc.gempa.de/tiles/{z}/{x}/{y}.png'
      attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      hasZoomed: false,
      epicenterMode: false,
      showEpicenter: false,
      guess: [],
      showEventOverview: false,
      events: [],
      clusterOptions: {
        zoomToBoundsOnClick: false,
      },
      pulseIcon: L.icon.pulse({iconSize:[20,20]}),
      currentStationIcon: L.icon({
        iconUrl: require('../assets/station-icon.png'),
        iconSize: [19, 16.5], // size of the icon
        shadowSize: [0, 0], // size of the shadow
        iconAnchor: [9.5, 8.25], // point of the icon which will correspond to marker's location
        shadowAnchor: [0, 0], // the same for the shadow
        popupAnchor: [0, -8.5], // point from which the popup should open relative to the iconAnchor
      }),
      stationIcon: L.icon({
        iconUrl: require('../assets/station-icon-deselected.png'),
        iconSize: [19, 16.5], // size of the icon
        shadowSize: [0, 0], // size of the shadow
        iconAnchor: [9.5, 8.25], // point of the icon which will correspond to marker's location
        shadowAnchor: [0, 0], // the same for the shadow
        popupAnchor: [0, -8.5], // point from which the popup should open relative to the iconAnchor
      }),
      guessIcon: L.icon({
        iconUrl: require('../assets/map-pin.png'),
        iconSize: [16, 30], // size of the icon
        shadowSize: [0, 0], // size of the shadow
        iconAnchor: [6, 10], // point of the icon which will correspond to marker's location
        shadowAnchor: [0, 0], // the same for the shadow
        popupAnchor: [0, -8.5], // point from which the popup should open relative to the iconAnchor
      }),
      eventIcon: L.icon({
        iconUrl: require('../assets/event-icon.png'),
        iconSize: [16.5, 16.5], // size of the icon
        shadowSize: [0, 0], // size of the shadow
        iconAnchor: [8.25, 8.25], // point of the icon which will correspond to marker's location
        shadowAnchor: [0, 0], // the same for the shadow
        popupAnchor: [0, -8.5], // point from which the popup should open relative to the iconAnchor
      })
    }
  },
  mounted() {
    //this.$refs.map.mapObject.fitWorld()
    this.zoomStart()
  },
  methods: {
    zoomStart() {
      this.hasZoomed = false
      var eqCirc = 40075000
      var polarCirc = 40008000
      var mapContainer = document.getElementById("map-container")
      var height = mapContainer.clientHeight
      var width = mapContainer.clientWidth
      var zoom = Math.log2(width) - 8;
      var zoomV = Math.log2(height * 0.95) - 8;
      var bestZoom = Math.max(zoom, zoomV)

      var map = this.$refs.map.mapObject
      map.flyTo([0,0], bestZoom)
    },
    zoomToStation (station) {
      var stationLatLng = L.latLng(station.latitude, station.longitude)
      var bounds = stationLatLng.toBounds(250000)
      this.hasZoomed = true
      this.$refs.map.mapObject.fitBounds(bounds)
      //this.$emit('update:bounds', bounds)
    },
    zoomBounds (bounds) {
      var fitArray = [
        [this.location[0] + bounds, this.location[1]],
        [this.location[0], this.location[1] + bounds],
        [this.location[0] - bounds, this.location[1]],
        [this.location[0], this.location[1] - bounds]
      ]
      this.$refs.map.fitBounds(fitArray)
    },
    setEvents (events) {
      this.events = events
      this.showEventOverview = true
    },
    hideEvents () {
      this.showEventOverview = false
    },
    addedEpicenter (event) {
      this.$nextTick(function () {
        event.target.togglePopup()
      });

    },
    eventClick (ev) {
      var latlng = ev.latlng
      if (ev.type === 'clusterclick') {
        var latlngs = ev.layer.getAllChildMarkers().map(cm => cm.getLatLng())
        latlng = latlngs[0]
        //var markerBounds = L.latLngBounds(latlngs)
        //console.log(latlngs)
        //console.log(latlng)
        //latlng = markerBounds.getCenter()
        //console.log(latlng)
      }
      console.log(ev)
      if (!this.hasZoomed) {
        var bounds = latlng.toBounds(250000)
        this.hasZoomed = true
        this.$refs.map.mapObject.fitBounds(bounds)
      }
    },
    onBoundsUpdated(bounds) {
      if (this.hasZoomed) {
        this.$emit('update:bounds', bounds)
      }
    },
    onMarkerClicked (station) {
      this.$emit('update:station', station)
    },
    onMapClicked (ev) {
      if (!this.hasZoomed) {
        var bounds = ev.latlng.toBounds(250000)
        this.hasZoomed = true
        this.$refs.map.mapObject.fitBounds(bounds)
      }
      if (this.epicenterMode) {
        this.$emit('update:epicenter', ev.latlng)
        this.guess = [ev.latlng.lat, ev.latlng.lng]
      }
    },
    enableEpicenterMode () {
      this.guess = []
      this.epicenterMode = !this.epicenterMode
      console.log('epicenter mode:', this.epicenterMode)
      if (this.epicenterMode) {
        L.DomUtil.addClass(this.$refs.map.mapObject._container,'crosshair-cursor-enabled')
        for (var i = 0; i < this.$refs.ranges.length; i++) {
          L.DomUtil.addClass(this.$refs.ranges[i].mapObject._path, 'crosshair-cursor-enabled')
        }
      } else {
        L.DomUtil.removeClass(this.$refs.map.mapObject._container,'crosshair-cursor-enabled')
        for (var i = 0; i < this.$refs.ranges.length; i++) {
          L.DomUtil.removeClass(this.$refs.ranges[i].mapObject._path, 'crosshair-cursor-enabled')
        }
      }
    },
    disableEpicenterMode () {
      this.epicenterMode = false
      L.DomUtil.removeClass(this.$refs.map.mapObject._container,'crosshair-cursor-enabled')
      if (this.$refs.ranges) {
        for (var i = 0; i < this.$refs.ranges.length; i++) {
          L.DomUtil.removeClass(this.$refs.ranges[i].mapObject._path, 'crosshair-cursor-enabled')
        }
      }
    },
    toggleEpicenterShow () {
      this.showEpicenter = !this.showEpicenter
    },
    disableEpicenterShow () {
      this.showEpicenter = false
    }
  },
  watch: {
    location: function(newVal, oldVal) {
      if (newVal && newVal.length > 0) {
        console.log(newVal)
        this.center = newVal
      }
    },
    event: function(newVal, oldVal) {
      this.showEpicenter = false
    },
    showCustomControls: function(newVal, oldVal) {
      if (newVal) {
        this.$nextTick(function () {
          var pickButton = this.$refs.epicenterControl.getPickElement()
          var tooltip = new Tooltip(pickButton, {
            title: 'Click here to pick or show the epicenter',
            placement: 'right'})
          tooltip.show()
          setTimeout(() => tooltip.dispose(), 5000)
        })
      }
    }
  }
}
</script>
<style lang="scss">
 @import 'leaflet-pulse-icon/src/L.Icon.Pulse.css';

 #map {
   width: 100%;
   height: 100%;
 }

 .leaflet-container.crosshair-cursor-enabled {
   cursor:crosshair;
 }

 .leaflet-interactive.crosshair-cursor-enabled {
   cursor: crosshair;
 }

 .leaflet-control-container .tooltip {
   background: #e62249;
   position: absolute;
   color: white;
   width: 150px;
   border-radius: 3px;
   box-shadow: 0 0 2px rgba(0,0,0,0.5);
   padding: 10px;
   text-align: center;
   left: 5px !important;
 }

 .leaflet-control-container .tooltip .tooltip-arrow {
   width: 0;
   height: 0;
   border-style: solid;
   position: absolute;
   margin: 5px;
   border-color: #e62249;
   border-width: 5px 5px 5px 0;
   border-left-color: transparent;
   border-top-color: transparent;
   border-bottom-color: transparent;
   left: -5px;
   top: calc(50% - 5px);
   margin-left: 0;
   margin-right: 0;
 }

.leaflet-pulsing-icon:after {
  margin: -95% 0 0 -145% !important;
}

.icon-legend {
  display: flex;
  flex-direction: column;
}

.icon-row {
  display: flex;
  flex-direction: row;
  padding: 0.25rem;
  p {
    padding-left: 0.15rem !important;
  }
}

</style>
