import Vue from 'vue'
import Root from './Root.vue'
import router from './router'
import store from './store'
import 'leaflet/dist/leaflet.css'
import Buefy from 'buefy'
import Notifications from 'vue-notification'
import VueTour from 'vue-tour'
import VueAnalytics from 'vue-analytics'
//import 'buefy/lib/buefy.css'
import 'vue-tour/dist/vue-tour.css'

Vue.use(Buefy)
Vue.use(VueTour)
Vue.use(Notifications)
Vue.use(VueAnalytics, {
  id: 'UA-77635855-5',
  autoTracking: {
    exception: true
  }
})

Vue.config.productionTip = false

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
  router,
  store,
  render: h => h(Root)
}).$mount('#app')
