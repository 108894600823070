import { UPDATE_AUTH } from '../mutation-types'

const loginState = {
  authenticated: false,
  user: null,
}

const mutations = {
  [UPDATE_AUTH](state, { authenticated, user }) {
    state.authenticated = authenticated
    state.user = user
    console.log(JSON.stringify(user))
  },
}

const getters = {
  authenticated: (state) => state.authenticated,
  user: (state) => state.user,
}

export default {
  state: loginState,
  mutations,
  getters,
}
