import * as types from '@/store/mutation-types'
import { getStations } from '@/services/fdsn.js'
import { clamp } from '@/util/clamp.js'

const state = {
  all: [],
  nearby: []
}

const mutations = {
  [types.INITIALIZE_STATIONS] (state, { stations }) {
    state.all = stations
  },
  [types.NEARBY_STATIONS] (state, { stations }) {
    state.nearby = stations
  }
}

const actions = {
  loadStations ({ commit }) {
    return getStations().then(stations => {
      stations.forEach(s => {
        s.name = s.network + '.' + s.station + '.' + s.channel + '.' + s.location
        s.shortName = s.network + '.' + s.station
      })
      commit(types.INITIALIZE_STATIONS, { stations: stations })
    })
  },
  loadNearbyStations ({ commit }, { lat, lon, radius, time }) {
    var params = { latitude: lat, longitude: lon, maxradius: radius, channel: '*HZ', startbefore: time.format('Y-M-D') }
    return getStations(params).then(stations => {
      stations.forEach(s => {
        s.name = s.network + '.' + s.station + '.' + s.channel + '.' + s.location
      })
      commit(types.NEARBY_STATIONS, { stations })
    })
  }
}

const getters = {
  getStationsByName: (state) => (name) => {
    return state.all.filter(s => s.name.includes(name.toUpperCase()))
  },
  getStationsByDistance: (state) => (lat, lon, radius) => {
    return state.all.filter(s => {
      return Math.abs(s.latitude) < lat + radius &&
             Math.abs(s.latitude) > lat - radius &&
             Math.abs(s.longitude) < lon + radius &&
             Math.abs(s.longitude) > lon + radius
    })
  },
  getStationsForBounds: (state) => (bounds) => {
    return state.all.filter(s => {
      return s.latitude < clamp(bounds.getNorth(), -90, 90) &&
            s.latitude > clamp(bounds.getSouth(), -90, 90) &&
            s.longitude > clamp(bounds.getWest(), -180, 180) &&
            s.longitude < clamp(bounds.getEast(), -180, 180)
    })
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
