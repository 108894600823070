import Vue from 'vue'
import Router from 'vue-router'

import App from './App.vue'
import Home from './views/Home.vue'

import LoginCallback from '@/views/LoginCallback.vue'
import SilentLogin from '@/views/SilentLogin.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'app',
      component: App,
      children: [
        {
          path: '/',
          name: 'home',
          component: Home
        }
      ]
    },
    {
      path: '/callback',
      name: 'login-callback',
      component: LoginCallback
    },
    {
      path: '/silent-login',
      name: 'silent-login',
      component: SilentLogin
    }
  ]
})
